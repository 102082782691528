<template>
  <section id="contentbox" class="h-100">
    <div class="form-login form-horizontal" id="register">
      <div class="bg-dark text-white border border-secondary rounded px-3 py-2 mt-2">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6">
            <div class="indexlogo">
              <!-- <div class="logoindex">
                <router-link to="welcome"><img src="@/assets/wisdom/logo wisdombet-03.png" /></router-link>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right">
            <h3 class="mt-1 font-weight-light"><i class="fas fa-key"></i> ลืมรหัสผ่าน</h3>
          </div>
        </div>
      </div>
      <div class="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2">
        <h5 class="text-danger">
          <span class="badge badge-pill badge-danger font-weight-light">STEP 3</span> เปลี่ยนรหัสผ่าน
        </h5>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
            <b>ชื่อผู้ใช้งาน <small> กรุณาเลือก</small></b>
          </div>
          <div class="col-12 col-sm-12 col-md-9 p-0 mb-2">
            <div class="row p-0 m-0">
              <div class="col-12 col-sm-12 col-md-9 p-0 mb-2">
                <div class="row p-0 m-0">
                  <template v-for="(item, index) in UsernameList">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3 text-center" :key="index">
                      <input type="radio" class="input-hidden" v-bind:value="item.profileId" :checked="profileSelect == item.profileID">
                      <label for="usr1" class="bank-radio border border-dark rounded w-100 pt-1" @click="clickProcess(item.profileID)">
                          <span class="badge badge-dark" style="font-size:14px;">ชื่อเข้าสู่ระบบ</span>
                          <span>{{ item.profileName }}</span>
                      </label>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
            <b>รหัสผ่านใหม่</b>
          </div>
          <div class="col-12 col-sm-12 col-md-9 mb-2">
            <input
              type="password"
              class="form-control"
              name="password"
              id="password_val"
              placeholder="password"
              v-model="password"
            />
            <span id="password_text"></span>
          </div>
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
            <b>ยืนยันรหัสผ่านใหม่</b>
          </div>
          <div class="col-12 col-sm-12 col-md-9 mb-2">
            <input
              type="password"
              class="form-control"
              name="repassword"
              id="repassword_val"
              placeholder="กรอก password อีกครั้ง"
              v-model="password_confirm"
            />
            <span id="repassword_text"></span>
          </div>
        </div>
      </div>
      <!-- <button class="btn btn-danger" @click="onTest">
        ทดสอบ function <i class="fas fa-chevron-right"></i>
      </button> -->
      <div class="bg-secondary border-dark shasow-sm rounded p-3 h-100 my-2 mb-5">
        <div class="row">
          <div class="col">
            <router-link to="/hotgame" tag="button" class="btn btn-dark btn-block">
              <i class="fas fa-chevron-left"></i> ย้อนกลับ
            </router-link>
          </div>
          <div class="col">
            <button class="btn btn-primary btn-block" @click="onSubmit" :disabled="checkPassword">
              ถัดไป <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { submitNewPasswordService } from '@/services'
import { isEmpty } from '@/utils/'
export default {
  data () {
    return {
      password: '',
      password_confirm: '',
      profileSelect: ''
    }
  },
  computed: {
    ...mapState({
      profileID: state => state.userModule.profileID,
      profileName: state => state.userModule.profileName,
      UsernameList: state => state.userModule.usernameList
    }),
    checkPassword () {
      return isEmpty(this.password) || isEmpty(this.password_confirm)
    }
  },
  mounted () {
    // alert('test')
  },
  methods: {
    ...mapActions(['setLoading']),
    async onSubmit () {
      try {
        if (this.profileSelect === '') {
          alert('กรุณารเลือก Username')
        }
        this.setLoading(true)
        const resp = await submitNewPasswordService({
          profileID: this.profileSelect,
          password: this.password,
          password_confirm: this.password_confirm
        })
        if (resp.status_code === 'SUCCESS') {
          this.$router.replace('/hotgame')
        } else {
          this.password = ''
          this.password_confirm = ''
        }
        this.setLoading(false)
      } catch (error) {
        this.password = ''
        this.password_confirm = ''
        this.setLoading(false)
      }
    },
    async onTest () {
      // console.log(this.UsernameList[0].profileID)
      // console.log(this.profileSelect)
      // console.log(this.password)
      // console.log(this.password_confirm)
    },
    clickProcess (val, id) {
      this.profileSelect = val
    }
  }
}
</script>

<style>
</style>
